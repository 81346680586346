import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <div class="bg-yellow-300 mb-2">
                <p class="text-center">Copyright bsmedia 2021</p>
            </div>
        );
    }
}

export default Footer;